import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";
import PageWrapper from "../components/PageWrapper";
import SEO from "../components/seo";
import { navigate } from "gatsby";
import { getAuthority } from "../msal/authConfig";

const Signout = () => { 
    const { instance } = useMsal();
    useEffect(() => {
        if ( instance.getAllAccounts().length ) {
            const policy = instance.getAllAccounts()[0].idTokenClaims.tfp
            instance.logoutRedirect({
                authority: getAuthority(policy),
                postLogoutRedirectUri: process.env.GATSBY_APP_B2C_LOGOUT_URI,
                onRedirectNavigate: () => !BrowserUtils.isInIframe()
            })
        } else {
            navigate("/")
        }
    }, [ instance ]);

    return (
        <PageWrapper headerConfig={{render: false}} footerConfig={{render: false}}>
            <SEO title="Sign out" />
            <div>We are signing you out...</div>
        </PageWrapper>
    )
}

export default Signout;
